import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1008.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,1008.000000) scale(0.100000,-0.100000)">


<path d="M5083 8725 c-38 -16 -63 -60 -63 -111 0 -28 8 -44 34 -70 34 -34 35
-34 123 -34 49 0 92 -3 96 -6 3 -3 1 -15 -6 -25 -9 -14 -8 -33 6 -83 9 -35 17
-69 17 -74 0 -5 -37 -12 -82 -16 -655 -54 -1215 -435 -1497 -1018 -254 -526
-236 -1140 48 -1654 121 -219 331 -454 534 -597 402 -283 903 -390 1390 -297
550 105 1035 482 1276 991 330 697 189 1514 -356 2051 -308 304 -670 474
-1109 520 -129 13 -124 9 -98 98 12 40 13 59 5 77 -6 13 -11 26 -11 28 0 2 45
5 100 7 91 3 102 5 125 29 54 53 36 157 -31 185 -47 20 -455 19 -501 -1z m432
-525 c521 -60 989 -354 1260 -790 116 -188 185 -362 231 -587 22 -108 26 -155
27 -303 1 -249 -31 -422 -119 -643 -198 -493 -617 -869 -1129 -1010 -94 -27
-223 -52 -229 -46 -14 15 -91 216 -121 314 -54 179 -76 321 -82 538 l-6 187
-58 0 -58 0 -7 -57 c-9 -76 10 -339 36 -481 23 -124 61 -254 122 -406 21 -54
36 -101 33 -104 -9 -9 -221 -3 -310 9 -560 73 -1060 439 -1306 954 -304 636
-185 1389 300 1897 243 254 518 414 851 494 168 40 390 54 565 34z"/>
<path d="M4796 7428 c-23 -33 -20 -54 17 -95 45 -51 77 -94 77 -103 0 -4 -41
3 -91 16 -149 39 -217 29 -106 -16 111 -46 260 -121 301 -153 23 -17 84 -68
137 -114 53 -46 99 -83 101 -83 3 0 38 33 80 73 98 95 204 156 437 253 202 83
216 94 186 140 -27 42 -55 46 -219 34 -208 -15 -276 -41 -390 -144 l-39 -35
-76 71 c-42 39 -99 86 -127 104 -110 70 -257 97 -288 52z"/>
<path d="M5940 7143 c-354 -139 -416 -168 -505 -240 -32 -26 -73 -68 -89 -91
l-31 -43 28 -47 c104 -174 149 -310 187 -562 12 -74 23 -136 25 -139 13 -13
158 74 209 126 109 109 151 239 180 549 17 186 35 243 103 335 25 35 46 73 46
86 0 58 -49 66 -153 26z"/>
<path d="M4687 7074 c49 -69 55 -112 48 -354 -10 -308 5 -406 80 -525 54 -85
136 -146 252 -184 72 -24 246 -38 308 -24 l40 9 3 59 c16 305 -201 718 -484
921 -63 45 -188 108 -238 120 l-30 7 21 -29z"/>
<path d="M4579 5908 c-25 -120 -12 -209 42 -292 66 -100 173 -152 298 -144 77
4 153 38 170 75 28 62 3 181 -50 230 -33 30 -119 68 -209 92 -82 22 -148 49
-195 81 -16 11 -33 20 -36 20 -4 0 -12 -28 -20 -62z"/>
<path d="M6022 5524 c-17 -8 -78 -19 -135 -24 -56 -6 -125 -13 -153 -16 -61
-6 -129 -45 -169 -98 -21 -28 -28 -50 -33 -101 -4 -61 -3 -68 22 -94 15 -15
48 -39 74 -52 40 -20 61 -24 142 -24 86 0 100 3 148 29 103 57 172 163 180
278 8 116 -5 133 -76 102z"/>
<path d="M7978 4133 c-15 -2 -37 -16 -48 -30 -18 -23 -20 -40 -20 -152 0 -69
4 -141 9 -159 6 -20 81 -118 181 -237 l171 -204 -3 -133 -3 -133 -122 -3 -123
-3 0 141 0 140 -50 0 -50 0 0 -159 c0 -220 -14 -206 219 -206 241 0 226 -14
225 215 -2 201 19 158 -201 419 l-153 180 0 120 0 121 125 0 125 0 0 -130 0
-130 50 0 50 0 0 144 c0 79 -3 152 -6 161 -14 36 -57 45 -206 44 -79 -1 -155
-4 -170 -6z"/>
<path d="M5936 4122 c-3 -3 -1 -16 87 -512 30 -168 68 -378 83 -467 l29 -163
48 0 c26 0 47 1 47 3 0 2 174 1051 185 1115 l6 32 -46 0 c-28 0 -47 -5 -50
-12 -2 -7 -18 -110 -36 -228 -66 -453 -108 -725 -108 -712 -2 31 -143 899
-149 921 -7 20 -15 24 -51 25 -23 0 -44 0 -45 -2z"/>
<path d="M6854 4116 c-17 -8 -34 -21 -38 -31 -3 -9 -6 -248 -6 -530 l0 -514
24 -28 24 -28 182 0 182 0 24 28 c23 27 24 34 24 183 l0 154 -50 0 -50 0 0
-140 0 -140 -130 0 -130 0 0 475 c0 261 -1 478 -2 483 -2 4 54 8 125 10 l127
3 2 -63 c2 -35 3 -92 3 -128 l1 -65 46 -3 c37 -2 47 0 50 14 2 9 1 79 -2 155
-4 115 -8 141 -23 156 -15 16 -40 18 -185 20 -125 2 -175 -1 -198 -11z"/>
<path d="M7453 4123 l-43 -4 0 -570 0 -569 188 2 187 3 0 40 0 40 -137 3 -138
3 0 224 0 225 119 0 119 0 4 45 3 45 -122 0 -123 0 0 215 0 215 136 0 135 0
-3 43 -3 42 -140 1 c-77 0 -159 -1 -182 -3z"/>
<path d="M4344 4113 c-57 -7 -69 -32 -72 -151 -7 -202 -15 -183 184 -423 l174
-210 0 -134 0 -135 -125 0 -125 0 -1 128 c-1 70 -1 133 0 140 1 8 -14 12 -49
12 l-50 0 0 -160 c0 -219 -7 -213 243 -208 212 4 202 -7 202 216 0 139 -3 172
-16 192 -9 14 -89 112 -178 218 l-162 192 3 118 3 117 123 3 122 3 0 -131 0
-130 50 0 50 0 0 144 c0 79 -3 152 -6 161 -14 36 -57 45 -201 43 -76 0 -152
-3 -169 -5z"/>
<path d="M4918 4113 l-48 -4 0 -570 0 -569 188 2 187 3 3 43 3 42 -140 0 -141
0 0 225 0 225 120 0 120 0 0 45 0 45 -120 0 -120 0 0 215 0 215 136 0 135 0
-3 43 -3 42 -135 1 c-74 0 -156 -1 -182 -3z"/>
<path d="M5385 4111 c-3 -2 -5 -260 -5 -573 l0 -568 50 0 50 0 0 530 0 530
130 0 130 0 0 -213 0 -213 -92 -3 c-51 -1 -95 -4 -97 -7 -5 -5 15 -66 104
-314 35 -96 74 -205 87 -242 l23 -68 47 0 c58 0 58 2 19 107 -58 154 -161 435
-161 439 0 2 20 4 44 4 55 0 104 17 116 40 14 26 13 482 -1 513 -20 43 -49 49
-251 46 -104 -2 -191 -5 -193 -8z"/>
<path d="M6543 3788 c4 -182 7 -439 7 -570 l0 -238 52 0 51 0 -6 347 c-4 190
-7 447 -7 570 l0 223 -52 0 -51 0 6 -332z"/>
<path d="M2606 4100 c-22 -4 -45 -16 -53 -26 -17 -23 -19 -1013 -3 -1061 17
-47 43 -53 231 -53 177 0 218 8 233 45 3 9 6 248 6 532 l0 515 -29 29 -29 29
-159 -1 c-87 -1 -176 -5 -197 -9z m316 -537 c1 -252 2 -469 2 -483 l1 -25
-143 -3 -142 -3 0 486 0 485 140 0 140 0 2 -457z"/>
<path d="M3228 4102 c-59 -7 -58 1 -58 -555 0 -446 2 -513 16 -543 21 -44 39
-47 252 -42 150 3 172 5 191 22 21 19 21 23 21 543 l0 525 -30 29 -30 30 -167
-3 c-93 -1 -180 -4 -195 -6z m323 -547 c1 -256 2 -473 3 -482 1 -16 -13 -18
-141 -21 l-143 -3 0 486 0 485 140 0 140 0 1 -465z"/>
<path d="M3838 4102 l-28 -3 0 -570 0 -569 183 2 182 3 0 40 0 40 -132 3 -133
3 -2 527 c-3 519 -3 527 -23 527 -11 0 -32 -1 -47 -3z"/>
<path d="M2000 4090 c-18 -4 -40 -16 -47 -26 -10 -14 -13 -127 -13 -537 l0
-519 30 -29 30 -30 176 3 176 3 24 28 c23 27 24 34 24 183 l0 154 -50 0 -50 0
0 -140 0 -140 -130 0 -130 0 0 485 0 485 125 0 125 0 0 -62 c0 -35 3 -93 7
-130 l6 -68 46 0 46 0 -3 154 c-4 202 8 190 -201 193 -86 1 -172 -2 -191 -7z"/>
<path d="M4966 1491 c-3 -5 0 -12 6 -14 10 -4 9 -8 -2 -16 -12 -9 -11 -11 8
-11 18 0 23 5 20 22 -3 23 -23 35 -32 19z"/>
<path d="M3875 1480 c-4 -6 -1 -10 4 -10 6 0 11 -5 11 -10 0 -7 -19 -9 -52 -5
-43 5 -49 4 -34 -6 16 -10 17 -20 11 -95 -4 -55 -3 -84 3 -84 6 0 14 40 17 88
l7 88 34 -4 c29 -4 34 -1 34 16 0 21 -26 37 -35 22z"/>
<path d="M4523 1464 c-16 -7 -1 -55 32 -98 31 -41 30 -66 -5 -83 l-25 -13 26
0 c56 0 67 59 19 113 -31 36 -39 72 -17 80 8 4 7 5 -3 5 -8 0 -20 -2 -27 -4z"/>
<path d="M4865 1459 c-4 -6 -4 -13 0 -17 4 -4 10 -1 12 6 3 6 13 12 24 12 17
0 19 -8 19 -95 0 -57 4 -95 10 -95 6 0 10 35 10 84 0 46 3 91 6 100 5 13 -2
16 -34 16 -23 0 -44 -5 -47 -11z"/>
<path d="M5978 1464 c-5 -4 -8 -39 -8 -78 0 -74 -5 -89 -30 -99 -10 -4 -8 -6
7 -6 25 -1 38 18 46 69 7 42 -5 125 -15 114z"/>
<path d="M6375 1460 c-3 -5 0 -7 8 -4 8 3 18 0 22 -6 13 -20 6 -108 -10 -139
-21 -42 0 -40 25 2 24 39 26 104 4 135 -16 23 -39 29 -49 12z"/>
<path d="M4208 1448 c7 -7 12 -43 12 -90 0 -45 4 -78 10 -78 11 0 14 138 4
164 -3 9 -13 16 -22 16 -12 0 -13 -3 -4 -12z"/>
<path d="M5623 1449 c14 -8 17 -24 17 -89 0 -47 4 -80 10 -80 11 0 14 138 4
164 -3 9 -16 16 -28 16 -19 -1 -19 -2 -3 -11z"/>
<path d="M4565 1440 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6027 1443 c8 -6 12 -34 12 -77 -1 -38 4 -73 9 -78 11 -11 32 -3 32
13 0 6 -4 8 -10 4 -5 -3 -10 24 -12 67 -3 64 -5 73 -23 76 -13 2 -16 0 -8 -5z"/>
<path d="M6336 1443 c-9 -10 -7 -83 3 -83 5 0 13 18 19 39 7 31 7 41 -3 45 -7
2 -16 2 -19 -1z"/>
<path d="M4155 1370 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4104 1356 c-9 -24 0 -59 16 -64 12 -4 13 -2 3 9 -7 7 -13 23 -13 36
0 17 4 21 17 16 11 -4 14 -3 9 5 -10 16 -25 15 -32 -2z"/>
<path d="M5522 1332 c2 -21 9 -37 18 -40 12 -4 13 -2 3 9 -20 20 -16 57 5 55
9 0 20 -8 23 -15 4 -11 7 -10 11 6 5 18 1 21 -29 21 -33 0 -34 -1 -31 -36z"/>
<path d="M4188 1353 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5935 1334 c0 -27 10 -32 18 -10 3 8 0 19 -6 24 -9 8 -12 4 -12 -14z"/>
<path d="M4505 1340 c-4 -6 -4 -14 -1 -17 3 -4 6 -1 6 5 0 7 7 12 15 12 18 0
20 -16 3 -23 -8 -3 -5 -6 5 -6 12 -1 17 5 15 16 -4 21 -33 29 -43 13z"/>
<path d="M6357 1331 c-3 -12 -1 -21 3 -21 15 0 23 21 12 32 -6 6 -11 2 -15
-11z"/>
<path d="M4005 1143 c-6 -1 -9 -29 -7 -64 5 -74 -3 -106 -26 -112 -13 -4 -12
-5 5 -6 28 -1 43 26 43 78 0 22 4 51 10 66 6 15 6 24 1 20 -5 -3 -11 1 -13 8
-2 8 -7 12 -13 10z"/>
<path d="M4058 1143 c22 -9 10 -93 -13 -93 -10 0 -9 -9 2 -42 19 -56 27 -63
19 -15 -5 26 -2 44 9 59 25 37 17 99 -14 97 -10 0 -11 -2 -3 -6z"/>
<path d="M4390 1136 c-18 -8 -25 -18 -24 -36 1 -14 -5 -34 -12 -45 -8 -11 -14
-28 -14 -38 0 -26 45 -67 72 -66 15 0 18 3 8 6 -47 17 -67 76 -35 103 12 10
13 16 4 26 -9 11 -7 15 5 20 9 3 13 10 10 15 -3 5 1 9 10 9 8 0 17 -6 19 -12
4 -8 6 -7 6 3 1 23 -19 28 -49 15z"/>
<path d="M5535 1139 c-11 -4 -14 -8 -7 -8 8 -1 12 -21 12 -67 0 -64 -1 -65
-17 -47 -10 10 -14 13 -9 6 5 -8 4 -13 -3 -13 -6 0 -11 -4 -11 -10 0 -5 7 -10
15 -10 21 0 19 -17 -2 -23 -10 -3 -2 -5 17 -4 19 0 49 -3 65 -7 l30 -7 -32 15
c-32 15 -32 15 -35 99 -3 79 -4 84 -23 76z"/>
<path d="M4813 1129 c14 -8 17 -24 17 -89 0 -47 4 -80 10 -80 11 0 14 138 4
164 -3 9 -16 16 -28 16 -19 -1 -19 -2 -3 -11z"/>
<path d="M5165 1131 c-3 -7 0 -8 9 -5 19 7 33 -23 18 -38 -8 -8 -7 -15 4 -25
21 -22 17 -61 -11 -88 -29 -30 -18 -33 19 -4 26 21 35 65 17 85 -5 5 -11 25
-13 44 -3 35 -29 53 -43 31z"/>
<path d="M5870 1132 c-9 -4 -15 -18 -14 -32 1 -14 -5 -34 -12 -45 -8 -11 -14
-28 -14 -38 0 -26 45 -67 72 -66 23 0 23 0 -2 10 -13 6 -28 15 -32 22 -13 20
-9 64 7 77 10 9 12 16 5 24 -6 8 -3 18 12 30 17 15 22 16 29 5 7 -11 9 -10 9
4 0 18 -30 22 -60 9z"/>
<path d="M5125 1123 c-12 -4 -7 -83 5 -83 11 0 19 51 11 70 -3 9 -11 15 -16
13z"/>
<path d="M4714 1016 c1 -25 6 -41 16 -44 12 -4 13 -2 3 9 -6 8 -13 28 -16 44
-4 23 -4 20 -3 -9z"/>
<path d="M4736 1039 c4 -7 44 0 44 7 0 2 -11 3 -24 2 -14 -1 -23 -5 -20 -9z"/>
<path d="M3964 1014 c-2 -26 11 -33 19 -10 3 8 0 19 -6 24 -9 8 -12 4 -13 -14z"/>
<path d="M4410 1011 c0 -11 5 -23 11 -27 7 -4 9 0 5 14 -5 15 -2 22 11 25 14
4 13 5 -4 6 -17 1 -23 -5 -23 -18z"/>
<path d="M5152 1010 c-10 -17 -10 -20 3 -20 8 0 15 9 15 20 0 11 -1 20 -3 20
-1 0 -8 -9 -15 -20z"/>
<path d="M5900 1011 c0 -11 5 -23 11 -27 8 -4 9 0 4 15 -4 15 -2 21 9 21 8 0
17 -9 19 -20 4 -17 4 -16 3 2 -1 31 -46 39 -46 9z"/>
<path d="M4451 994 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
</g>
</svg>





    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
